<script setup lang="ts">
import type { NovaInputEmits, NovaInputProps } from './NovaInput.types'

const emit = defineEmits<NovaInputEmits>()
const props = withDefaults(defineProps<NovaInputProps>(), {
  label: '',
  labelSub: '',
  align: 'left',
  id: '',
  type: 'text',
  class: '',
  placeholder: '',
  prefix: '',
  theme: 'default',
  maxByte: -1,
  maxLength: -1,
  showMax: true,
  max: 0,
  isClear: true,
  formatter: null,
  unFormatter: null,
  readonly: false,
  disabled: false,
})
const {
  modelValue,
  maxByte,
  max,
  theme,
  formatter: propFormatter,
  unFormatter: propUnFormatter,
} = toRefs(props)
const { gtEvent } = useGoogleTag()
const slots = useSlots()
const input = ref<HTMLInputElement | null>(null)
const inputValue = ref<string | number>(modelValue.value)
const themeClass = computed(() => `theme-${theme.value}`)
const countByte = computed(() => useCalcByte(String(modelValue.value)))
const countLength = computed(() => String(modelValue.value).length)

watch(
  () => modelValue.value,
  (cur) => {
    inputValue.value = formatter(cur)
  }
)

const handleOnUpdate = (e: any) => {
  if (
    maxByte.value !== -1 &&
    countByte.value >= maxByte.value &&
    e.target.value > modelValue.value
  ) {
    e.target.value = modelValue.value
    return
  }

  let updateValue = unFormatter(e.target.value)
  if (max.value && max.value <= updateValue) {
    const override = formatter(max.value)
    e.target.value = override
    updateValue = override
  }

  emit('update:modelValue', updateValue)
  emit('on-change')
}
const formatter = (value: string | number | null) =>
  typeof propFormatter.value === 'function' ? propFormatter.value(value) : value

const unFormatter = (value: string | number | null) =>
  typeof propUnFormatter.value === 'function'
    ? propUnFormatter.value(value)
    : value

const handleOnClear = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '작성중인 내용 초기화',
    eventLabel: 'clear',
    eventSlot: '',
    eventI18nAddr: 'clear',
    eventComponent: 'Button',
  })
  emit('update:modelValue', typeof modelValue.value === 'number' ? 0 : '')
  emit('on-clear')

  // x버튼 눌러도 포커스 유지(키보드 안내려가게 유지)
  nextTick(() => {
    input.value?.focus()
  })
}
const handleOnSearch = () => {
  emit('on-search')
  setTimeout(() => {
    input.value?.blur()
  })
}
</script>

<template>
  <div :class="['input-group', themeClass, { readonly, disabled }]">
    <label v-if="label" class="input-label">
      {{ label }}
      <em v-if="labelSub">{{ labelSub }}</em>
    </label>

    <div class="inner">
      <div class="input-box">
        <div :id="id" class="input-wrap">
          <span v-if="prefix" class="prefix">{{ prefix }}</span>

          <input
            ref="input"
            :type="type"
            :value="inputValue"
            :class="['input', $props.class, `align-${align}`]"
            :placeholder="placeholder"
            :readonly="readonly"
            :disabled="disabled"
            :maxlength="maxLength === -1 ? 99999 : maxLength"
            size="1"
            @input="handleOnUpdate"
            @focus="$emit('on-focus')"
            @blur="$emit('on-blur')"
            @keydown.enter="handleOnSearch"
          />
        </div>

        <div class="input-tools">
          <NovaButtonIcon
            v-if="isClear"
            :icon="{ type: 'outline', name: 'clear' }"
            :size="14"
            :class="['btn-clear', { on: modelValue }]"
            @click="handleOnClear"
          />

          <em v-if="maxByte > 0 && showMax" class="count-box">
            ({{ countByte }}/{{ maxByte }})
          </em>

          <em v-if="maxLength > 0" class="count-box">
            ({{ countLength }}/{{ maxLength }})
          </em>

          <slot name="tools" />
        </div>
      </div>

      <div v-if="slots['actions']" class="actions">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  &.disabled {
    opacity: 0.6;
  }

  .input-label {
    text-align: left;
    @include text-style($text-body-14-bold);
    color: $color-text-2;

    > em {
      @include text-style($text-body-11-medium);
      color: $color-text-3;
      margin-left: 4px;
    }
  }

  &.theme-search {
    > .inner .input-box {
      padding: 0 20px;
      background-color: $color-bg-1;
      border: 0;

      &::placeholder {
        color: $color-text-3;
      }

      > input {
        color: $color-text-4;
      }
    }
  }

  &.theme-bgwhite {
    > .inner .input-box {
      background-color: $color-white;
    }
  }

  &.theme-destroy {
    > .inner .input-box {
      border-color: #727272;
      border-radius: 4px;
    }
  }

  > .inner {
    flex-wrap: wrap;
    display: flex;
    align-items: flex-start;
    gap: 12px;

    .input-box {
      flex-grow: 1;
      display: flex;
      gap: 10px;
      width: auto;
      height: 40px;
      padding: 0 15px;
      border-radius: 8px;
      border: 1px solid $color-bg-custom-2;

      .input-wrap {
        display: flex;
        align-items: center;
        flex-grow: 1;

        > .prefix {
          flex-shrink: 0;
          @include text-style($text-body-14-regular);
          color: $color-text-2;
        }

        > input {
          @include text-style($text-body-14-regular);
          color: $color-text-2;
          padding: 0;
          flex-grow: 1;
          background-color: transparent;

          &.align {
            &-left {
              text-align: left;
            }

            &-center {
              text-align: center;
            }

            &-right {
              text-align: right;
            }
          }

          &::placeholder {
            color: $color-text-3;
          }
        }
      }
    }

    .input-tools {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      gap: 12px;

      .btn-clear {
        transform: scale(0);
        pointer-events: none;
        @include transition(transform 0.15s);

        &.on {
          transform: scale(1);
          pointer-events: auto;
        }
      }

      .count-box {
        color: $color-text-3;
        @include text-style($text-body-12-regular);
      }
    }

    .actions {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}
</style>
