/**
 * 주어진 문자열이 사용하는 바이트 수를 계산
 *
 * @param {string} text - 바이트 수를 계산할 문자열.
 * @returns {number} - 주어진 문자열의 바이트 수.
 */
export const useCalcByte = (text: string): number => {
  let intCharCount = 0

  for (let i = 0; i < text.length; i++) {
    const char = text.charAt(i)
    let encoded = ''
    try {
      encoded = encodeURIComponent(char)
    } catch (e) {
      encoded = ''
    }
    if (encoded.length > 1) {
      intCharCount += 2
    } else {
      intCharCount++
    }
  }

  return intCharCount
}
