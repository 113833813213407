<script setup lang="ts">
import type { CSSProperties } from 'vue'
import type {
  ShowPostDetailModalParams,
  NovaBoxPostProps,
} from './NovaBoxPost.types'
import { type Hashtag, PostContentsType } from '@store'
import { ROUTES } from '@configs'

const props = withDefaults(defineProps<NovaBoxPostProps>(), {
  listViewHeight: 'auto',
})
const { gtEvent } = useGoogleTag()
const route = useRoute()
const postStore = usePostStore()
const { humanize } = useDateFormat()
const { show: modalShow, hide: hideModal } = useModal()
const postRef = ref<HTMLElement>()
const actionAppendTarget = ref<Element | null>(null)
const createAt = computed(() => humanize(props.source.nttRegistDt))
const heightStyle = computed<CSSProperties>(() => {
  let height = 'auto'

  if (
    props.viewType === PostContentsType.LIST &&
    props.listViewHeight !== 'auto'
  ) {
    height = useGetStyleSize(props.listViewHeight)
  }

  return { height }
})
const visibilityObserver = ref<IntersectionObserver>()

onMounted(() => {
  setActionAppendTarget()

  if (postRef.value) {
    visibilityObserver.value = useObserveElementVisibility({
      element: postRef.value,
      callback: () => {
        gtEvent('viewEvent', {
          eventCategory: '콘텐츠 노출',
          eventAction: '포스트 노출',
          eventLabel: props.source.nttSj,
          eventSlot:
            props.viewType === PostContentsType.DETAIL
              ? '포스트 상세'
              : '포스트 리스트',
          eventI18nAddr: '',
          eventComponent: 'NovaBoxPost',
          cmtyNttCtgrySn: props.source.cmtyNttCtgrySn,
          cmtyNttSn: props.source.cmtyNttSn,
          nttSj: props.source.nttSj,
          userNcnm: props.source.userNcnm,
          userSn: props.source.userSn,
        })
      },
    })
  }
})

onBeforeUnmount(() => {
  visibilityObserver.value?.disconnect()
})

// 포스트 상세 모달 열기
const handleOnOpenPostDetail = async (type?: string) => {
  if (props.source.prmbrshSbscrbAt === 'N') return

  if (props.viewType === 'list') {
    const videos: any = document.getElementsByTagName('video')

    for (const i in videos) {
      const video: HTMLVideoElement =
        document.getElementsByTagName('video')[parseInt(i)]
      video?.pause()
    }

    if (props.openDetailUrl) {
      gtEvent('clickEvent', {
        eventCategory: '클릭',
        eventAction: '포스트 상세보기',
        eventLabel: props.source.nttSj,
        eventSlot: '',
        eventI18nAddr: '',
        eventComponent: 'NovaBoxPost',
        ...props.source,
      })
    }
    props.openDetailUrl
      ? await useNavigations({ url: props.openDetailUrl, query: route.query })
      : await modalShow(modalsName.MODAL_POST_DETAIL, {
          postId: props.source.cmtyNttSn,
          userSn: props.source.userSn,
        } as ShowPostDetailModalParams)

    if (type === 'comment') {
      let isMoved = false
      const observer = new MutationObserver(() => {
        const targetElement = document.querySelector(
          '.post-comments-wrap'
        ) as HTMLElement | null
        if (targetElement) {
          observer.disconnect()
          const contents = document.querySelector(
            '.post-contents-snapshot'
          ) as HTMLElement | null
          const images = contents?.querySelectorAll(
            '.image-container img'
          ) as NodeListOf<HTMLImageElement>

          if (!images || images.length === 0) {
            isMoved = true
            setTimeout(() => {
              targetElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              })
            }, 500)
            return
          }
          let loadedCount = 0
          images.forEach((img) => {
            if (img.complete) {
              loadedCount++
              if (loadedCount === images.length) {
                isMoved = true
                setTimeout(() => {
                  targetElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  })
                }, 500)
              }
            } else {
              console.log('⏳ 이미지 로딩 중:', img.src)
              img.addEventListener('load', () => {
                loadedCount++
                if (loadedCount === images.length) {
                  isMoved = true
                  setTimeout(() => {
                    targetElement.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    })
                  }, 500)
                }
              })
            }
          })

          if (!isMoved) {
            setTimeout(() => {
              targetElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              })
            }, 1000)
          }
        }
      })

      observer.observe(document.body, { childList: true, subtree: true })
    }
  } else if (props.viewType === 'detail') {
    const targetElement = document.querySelector(
      '.post-comments-wrap'
    ) as HTMLElement | null
    if (targetElement) {
      setTimeout(() => {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }, 500)
    }
  }
}

const setActionAppendTarget = () => {
  switch (props.viewType) {
    case 'list':
      actionAppendTarget.value = document.querySelector('.posts-list')
      break
    case 'detail':
      actionAppendTarget.value = document.querySelector('.post-index')
  }
}

const handleOnGoHashtag = async (hashtag: Hashtag) => {
  postStore.updateHashtagControl(hashtag)

  await hideModal(modalsName.MODAL_POST_DETAIL)

  const routeName = useGetRouteName()
  await useNavigations({
    url: ROUTES.HASHTAG.path,
    query: postStore.hashtagControl.hashtag,
    type: routeName.includes(ROUTES.HASHTAG.name) ? 'replace' : 'push',
  })
  setTimeout(() => {
    window.scrollTo({ top: 0 })
  }, 300)
}
</script>

<template>
  <article
    ref="postRef"
    :class="['post', `view-type-${viewType}`]"
    :style="{ ...heightStyle }"
    @click="handleOnOpenPostDetail()"
  >
    <div class="header">
      <div class="category-bar">
        <NovaBoxPostCategory
          v-if="source.cmtyNttCtgrySn"
          :cmty-ntt-ctgry-sn="source.cmtyNttCtgrySn"
        />

        <div v-if="source.nttAiPrcuseAt === 'Y'" class="extra">
          <NovaBadgeAI class="badge-ai" />
        </div>
      </div>

      <div class="info-bar">
        <div class="left-wrap">
          <NovaUserCell
            :user-name="source.userNcnm"
            :post-create-at="createAt"
            :portrait="source.proflMediaInfo"
            :align-vertical="true"
            :lock="source.nttOthbcScopeCode === 'PRIV'"
            :user-sn="source.userSn"
            :creator-link="source.creatorLink || ''"
            :view-type="viewType"
            :cmty-user-se-code="source.cmtyUserSeCode"
          />

          <NovaCreatorGradeBadge
            v-if="source.cmtyUserSeCode === 'C'"
            :creator-grade="source.crtrSpclGradCode"
            :creator-organization="source.crtrOrgnztTyCode"
            class="creator-badge"
          />

          <!--          <span class="create-at">{{ createAt }}</span>-->

          <ClientOnly>
            <NovaFollowUnfollowButton
              :post-user-sn="source.userSn"
              :follow-flag="source.followFlag"
              :user-ncnm="source.userNcnm"
              class="follow"
            />
          </ClientOnly>
        </div>

        <div class="meta-info">
          <span v-if="viewType === 'detail'" class="meta">
            {{ $t('displayCount') }}
            {{ useFormatThousandsSeparators(source.rdCount) }}
          </span>
        </div>
      </div>
    </div>

    <div class="contents">
      <div class="post-title-wrap">
        <NovaBadgeLive v-if="source.nttSndbrdLiveAt === 'Y'" />
        <h4
          v-dompurify-html="source.nttSj"
          :class="[
            'post-title',
            { 'live-badge': source.nttSndbrdLiveAt === 'Y' },
          ]"
        />
      </div>

      <NovaBoxPostContentsSnapshot
        v-if="source.prmbrshSbscrbAt !== 'N'"
        :ntt-ty-code="source.nttTyCode"
        :image-or-video-list="source.imageOrVideoList"
        :editor-contents="source.nttCn || ''"
        :view-type="viewType"
        :is-resizing-images="source.isUpdateBefore"
      />

      <!--프리미엄 컨텐츠 일때-->
      <NovaBoxPostAccessBlock
        v-else
        :creator-user-sn="source.userSn"
        :blur-image-url="source.blurFileUrl || ''"
      />

      <div
        v-if="source.hashTagList?.length || source.recommendUserList?.length"
        class="extras"
      >
        <NovaHashtags
          class="hashtags"
          :hashtags="source.hashTagList || []"
          :prefix="'#'"
          :able-action="true"
          @on-change-active-hashtag="handleOnGoHashtag"
        />

        <NovaRecommendUsers
          v-if="source.recommendUserList?.length"
          :recommend-users="source.recommendUserList"
          :recommend-count="source.rcCount"
          :popover-append-target="actionAppendTarget"
        />
      </div>
    </div>

    <NovaDivider />

    <NovaBoxPostActions
      :source="source"
      :popover-append-target="actionAppendTarget"
      :view-type="viewType"
      @go-detail-page="handleOnOpenPostDetail"
    />

    <NovaBoxPostComments
      v-if="viewType === 'detail'"
      :view-type="viewType"
      :cmty-ntt-sn="source.cmtyNttSn"
      :answer-count="source.answerCount"
    />
  </article>
</template>

<style lang="scss" scoped>
.post {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  //max-height: 634px;
  padding: 20px;
  border-radius: $radius-10;
  background-color: $color-bg-3;
  box-shadow: 5px 5px 10px 0 hex-to-rgba($color-bg-custom-4, 0.05);
  @include transition(box-shadow 0.2s);

  &.view-type {
    &-list {
      cursor: pointer;

      @media (hover: hover) {
        &:hover {
          box-shadow: 0 5px 10px 10px hex-to-rgba($color-bg-custom-4, 0.05);
        }
      }
    }

    &-detail {
      //max-height: 100%;
      //.contents .post-contents { height: auto; }
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .category-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;

      .extra {
        position: relative;
        flex-shrink: 0;

        .badge-ai {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }
    }

    .info-bar {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: space-between;

      .left-wrap {
        display: flex;
        align-items: center;

        .creator-badge {
          margin-left: 6px;
        }

        .create-at {
          margin-left: 20px;
          @include text-style($text-body-11-medium);
          color: $color-text-3;
        }

        > .follow {
          margin-left: 20px;
        }
      }

      .meta-info {
        flex-shrink: 0;
        display: flex;
        gap: 20px;

        .meta {
          @include text-style($text-body-14-regular);
          color: $color-text-3;
        }
      }
    }
  }

  .contents {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;

    .post-title-wrap {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      gap: 4px;

      .post-title {
        width: 100%;
        flex-shrink: 0;
        @include text-style($text-heading2-bold);
        color: $color-text-2;
        @include ellipsis(2);
        &.live-badge {
          max-width: calc(100% - 60px);
        }
      }
    }

    > .inner {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      gap: 20px;
      overflow: hidden;
    }

    > .extras {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;

      .hashtags {
        flex-grow: 1;
      }
    }
  }

  @include mobile {
    gap: 12px;
    padding: 16px;

    .header {
      gap: 8px;
    }

    .contents {
      gap: 10px;

      .post-title {
        @include text-style($text-display-bold);
      }

      > .inner {
        gap: 10px;
      }
    }

    &.view-type-list {
      .contents > .extras .hashtags {
        /*display: none;*/
      }
    }
  }
}
</style>
