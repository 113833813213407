<script setup lang="ts">
import type { NovaRecommendUsersPanelProps } from './NovaRecommendUsersPanel.types'

const props = defineProps<NovaRecommendUsersPanelProps>()

const { t } = useI18n()
const othersCount = computed(
  () => props.recommendCount - props.recommendUsers.length
)

const handleOnGoUserHome = (userSn: number) => {
  useGoUserHome(userSn)
}
</script>

<template>
  <NovaBoxBase class="recommend-users" :floating="true">
    <NovaLayoutScrollContainer class="scroll">
      <div
        v-for="user in recommendUsers"
        :key="user.userSn"
        type="button"
        class="user-cell"
        @click.stop="handleOnGoUserHome(user.userSn)"
      >
        <NovaPortraitContainer :image-url="user.profileImgUrl" :size="'xs'" />

        <div class="user-info">
          <span class="user-name">{{ user.userNcnm }}</span>
        </div>
      </div>

      <div v-if="othersCount" class="others-count">
        {{ t('recommendOthersCount', { count: othersCount }) }}
      </div>
    </NovaLayoutScrollContainer>
  </NovaBoxBase>
</template>

<style lang="scss" scoped>
.recommend-users {
  width: 200px;
  max-height: 300px;
  padding: 0 !important;

  .scroll {
    padding: 10px 0;

    .others-count {
      position: relative;
      margin-top: 8px;
      padding: 10px 20px 5px;
      text-align: center;
      @include text-style($text-body-13-medium);
      color: $color-text-2;

      &:before {
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        content: '';
        width: calc(100% - 40px);
        height: 1px;
        background-color: $color-bg-2;
        transform: translateX(-50%);
      }
    }
  }

  .user-cell {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 5px 20px;

    .user-info {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .user-name {
        @include text-style($text-body-14-medium);
      }
    }
  }
}
</style>
