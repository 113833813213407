<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type { NovaRecommendUsersProps } from './NovaRecommendUsers.types'

const props = withDefaults(defineProps<NovaRecommendUsersProps>(), {
  popoverAppendTarget: null,
  viewType: 'post',
})

const { t } = useI18n()
// const appendToRef = ref<Element | null>(null)
const reversedRecommendUsers = computed(() =>
  [...props.recommendUsers].reverse()
)
const limitUsers = computed(() => reversedRecommendUsers.value.slice(0, 5))
const othersCount = computed(
  () => props.recommendCount - limitUsers.value.length
)

const handleOnGoUserHome = (userSn: number) => {
  useGoUserHome(userSn)
}
</script>

<template>
  <div :class="['recommend-users', `view-type-${viewType}`]">
    <div class="users">
      <NovaRecommendUser
        v-for="user in limitUsers"
        :key="user.userSn"
        :user="user"
        @click.stop="handleOnGoUserHome(user.userSn)"
      />
    </div>

    <div v-if="othersCount > 0" class="extra-users">
      <Tippy
        :append-to="popoverAppendTarget || 'parent'"
        :interactive="true"
        :theme="'popover'"
        :placement="'bottom-end'"
        @click.stop="() => {}"
      >
        <p class="count">
          <span class="desktop">
            {{ t('recommendOthersCount', { count: othersCount }) }}
          </span>
          <span v-if="viewType === 'comment'" class="mobile">
            {{ `+${othersCount}` }}
          </span>
        </p>
        <template #content>
          <NovaRecommendUsersPanel
            :recommend-users="recommendUsers"
            :recommend-count="recommendCount"
          />
        </template>
      </Tippy>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.recommend-users {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 4px;

  .users {
    display: flex;
    padding-left: 4px;
    cursor: pointer;
  }

  .extra-users {
    flex-shrink: 0;

    .count {
      flex-shrink: 0;
      @include text-style($text-body-12-medium);
      color: $color-text-2;

      .mobile {
        display: none;
      }
    }
  }

  @include mobile {
    &.view-type-comment {
      .extra-users {
        .count {
          .mobile {
            display: inline-block;
          }

          .desktop {
            display: none;
          }
        }
      }
    }
  }
}
</style>
