import validate from "/codebuild/output/src2563597518/src/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45language_45global from "/codebuild/output/src2563597518/src/middleware/01.language.global.ts";
import _02_45auto_45sign_45in_45global from "/codebuild/output/src2563597518/src/middleware/02.autoSignIn.global.ts";
import _03_45server_45side_45req_45header_45global from "/codebuild/output/src2563597518/src/middleware/03.serverSideReqHeader.global.ts";
import _04_45require_45fetch_45global from "/codebuild/output/src2563597518/src/middleware/04.requireFetch.global.ts";
import _05_45auth_45guard_45global from "/codebuild/output/src2563597518/src/middleware/05.authGuard.global.ts";
import _06_45system_45back_45modal_45global from "/codebuild/output/src2563597518/src/middleware/06.systemBackModal.global.ts";
import _07_45page_45guard_45global from "/codebuild/output/src2563597518/src/middleware/07.pageGuard.global.ts";
import manifest_45route_45rule from "/codebuild/output/src2563597518/src/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45language_45global,
  _02_45auto_45sign_45in_45global,
  _03_45server_45side_45req_45header_45global,
  _04_45require_45fetch_45global,
  _05_45auth_45guard_45global,
  _06_45system_45back_45modal_45global,
  _07_45page_45guard_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "apply-dao-guard": () => import("/codebuild/output/src2563597518/src/middleware/applyDaoGuard.ts"),
  "apply-kpop-festival-guard": () => import("/codebuild/output/src2563597518/src/middleware/applyKpopFestivalGuard.ts"),
  "apply-rent-complete-guard": () => import("/codebuild/output/src2563597518/src/middleware/applyRentCompleteGuard.ts"),
  "apply-rent-form-additional-guard": () => import("/codebuild/output/src2563597518/src/middleware/applyRentFormAdditionalGuard.ts"),
  "apply-rent-form-guard": () => import("/codebuild/output/src2563597518/src/middleware/applyRentFormGuard.ts"),
  "apply-rent-guard": () => import("/codebuild/output/src2563597518/src/middleware/applyRentGuard.ts"),
  "apply-super-moon-form-guard": () => import("/codebuild/output/src2563597518/src/middleware/applySuperMoonFormGuard.ts"),
  "auth-guard": () => import("/codebuild/output/src2563597518/src/middleware/authGuard.ts"),
  "creator-page-guard": () => import("/codebuild/output/src2563597518/src/middleware/creatorPageGuard.ts"),
  "user-page-guard": () => import("/codebuild/output/src2563597518/src/middleware/userPageGuard.ts")
}