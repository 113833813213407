<script setup lang="ts">
import type {
  NovaBoxPostActionsEmits,
  NovaBoxPostActionsProps,
} from './NovaBoxPostActions.types'

withDefaults(defineProps<NovaBoxPostActionsProps>(), {
  popoverAppendTarget: null,
})
const emit = defineEmits<NovaBoxPostActionsEmits>()
const goDetailPage = (type: string) => {
  emit('goDetailPage', type)
}
</script>

<template>
  <div class="post-actions">
    <div class="actions-wrap">
      <NovaBoxPostAction
        :icon="{ type: 'outline', name: 'comment' }"
        :display-number="source.answerCount"
        :action-type="'comment'"
        @click.stop="goDetailPage('comment')"
      />

      <NovaBoxPostActionRecommend
        :recommend-target-type="'post'"
        :recommend-target="source.cmtyNttSn"
        :rc-flag="source.rcFlag || '0'"
        :dv-flag="source.dvFlag || '0'"
        :recommend-count="source.rcCount"
        :recommend-user-list="source.recommendUserList"
        :action-able-recommend="source.prmbrshSbscrbAt !== 'N'"
        :view-type="viewType"
      />
    </div>

    <ClientOnly>
      <NovaBoxPostActionPrivate
        v-if="source.prmbrshSbscrbAt !== 'N'"
        :source="source"
        :popover-append-target="popoverAppendTarget"
      />
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped>
.post-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  .actions-wrap {
    display: flex;
    gap: 18px;
    z-index: 10;
  }

  @include mobile {
    .actions-wrap {
      gap: 12px;
    }
  }
}
</style>
