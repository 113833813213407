import { default as applyJl9J2zMiUMMeta } from "/codebuild/output/src2563597518/src/pages/apply-creator/apply.vue?macro=true";
import { default as indexNwJGM9s1s3Meta } from "/codebuild/output/src2563597518/src/pages/apply-creator/index.vue?macro=true";
import { default as formofTXvdtbthMeta } from "/codebuild/output/src2563597518/src/pages/apply-dao/form.vue?macro=true";
import { default as indexdYfmjrOhtsMeta } from "/codebuild/output/src2563597518/src/pages/apply-dao/index.vue?macro=true";
import { default as formCu4QrGcbRnMeta } from "/codebuild/output/src2563597518/src/pages/apply-kpop-cover-dance/form.vue?macro=true";
import { default as additionalSMnhPWirAsMeta } from "/codebuild/output/src2563597518/src/pages/apply-rent/facility-[id]/additional.vue?macro=true";
import { default as completeHo65W8Jc06Meta } from "/codebuild/output/src2563597518/src/pages/apply-rent/facility-[id]/complete.vue?macro=true";
import { default as index9XX9R7eiGTMeta } from "/codebuild/output/src2563597518/src/pages/apply-rent/facility-[id]/index.vue?macro=true";
import { default as indexLPEYxcXZY1Meta } from "/codebuild/output/src2563597518/src/pages/apply-rent/index.vue?macro=true";
import { default as form4WI7z6y6CzMeta } from "/codebuild/output/src2563597518/src/pages/apply-super-moon/form.vue?macro=true";
import { default as indexZiCjQpScknMeta } from "/codebuild/output/src2563597518/src/pages/apply-super-moon/index.vue?macro=true";
import { default as _91id_931fY9ATL6LmMeta } from "/codebuild/output/src2563597518/src/pages/board/event/[id].vue?macro=true";
import { default as indexSQQLMglvn2Meta } from "/codebuild/output/src2563597518/src/pages/board/event/index.vue?macro=true";
import { default as _91id_93OE0Smtfm6LMeta } from "/codebuild/output/src2563597518/src/pages/board/faq/[id].vue?macro=true";
import { default as indexSwoHWkmeCtMeta } from "/codebuild/output/src2563597518/src/pages/board/faq/index.vue?macro=true";
import { default as _91id_93YOIzoP25l0Meta } from "/codebuild/output/src2563597518/src/pages/board/news/[id].vue?macro=true";
import { default as index94YGWYUWxsMeta } from "/codebuild/output/src2563597518/src/pages/board/news/index.vue?macro=true";
import { default as _91id_932sXlFWHkvNMeta } from "/codebuild/output/src2563597518/src/pages/board/notice/[id].vue?macro=true";
import { default as indexsszPPtiPItMeta } from "/codebuild/output/src2563597518/src/pages/board/notice/index.vue?macro=true";
import { default as paygweABX79v5Meta } from "/codebuild/output/src2563597518/src/pages/callback/pay.vue?macro=true";
import { default as signinSZ7PHPa9P9Meta } from "/codebuild/output/src2563597518/src/pages/callback/signin.vue?macro=true";
import { default as signupzkUPoAaNZAMeta } from "/codebuild/output/src2563597518/src/pages/callback/signup.vue?macro=true";
import { default as _91postId_93Gobwb93sxkMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/activities/[postId].vue?macro=true";
import { default as activitiesPcKgJDrSn7Meta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/activities.vue?macro=true";
import { default as _91collectionId_93HiDUpkXgupMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/collections/[collectionId].vue?macro=true";
import { default as collections0MRLnojiwMMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/collections.vue?macro=true";
import { default as _91daoPostId_9330jybtzAjdMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/dao/board/[daoPostId].vue?macro=true";
import { default as indexXmi9TBLRmdMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/dao/board/index.vue?macro=true";
import { default as indexQ86N3lJcdPMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/dao/index.vue?macro=true";
import { default as membermwABYV279HMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/dao/member.vue?macro=true";
import { default as _91voteId_93S9TUuqwl82Meta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/dao/vote/[voteId].vue?macro=true";
import { default as voteIx6JBl0kxAMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/dao/vote.vue?macro=true";
import { default as donationZGMrDU5YbFMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/donation.vue?macro=true";
import { default as guestbooksebQn1fsQAMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/guestbook.vue?macro=true";
import { default as _91postId_93WikFdIOxuxMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/index/[postId].vue?macro=true";
import { default as indexXP28758SwtMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/index.vue?macro=true";
import { default as _91noticeId_93W9uWo5yIXiMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/notice/[noticeId].vue?macro=true";
import { default as notice8pvCv2k5LLMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/notice.vue?macro=true";
import { default as _91postId_93AS1Vqkt6SiMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/nova-plus/[postId].vue?macro=true";
import { default as indexVlPG1lynjGMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/nova-plus/index.vue?macro=true";
import { default as _91postId_93My4ySasZzkMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/posts/[postId].vue?macro=true";
import { default as indexEFwYqWZBDzMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/posts/index.vue?macro=true";
import { default as reward5GnYmss0wVMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/reward.vue?macro=true";
import { default as statisticsyTbdrLmThNMeta } from "/codebuild/output/src2563597518/src/pages/creator/[id]/statistics.vue?macro=true";
import { default as sponsorsKkfZaP0d95Meta } from "/codebuild/output/src2563597518/src/pages/creator/sponsors.vue?macro=true";
import { default as _91creatorUserSn_93WmXCwkAsr4Meta } from "/codebuild/output/src2563597518/src/pages/creator/subscribe/cancel/[creatorUserSn].vue?macro=true";
import { default as _91creatorUserSn_93j5sSzexW0DMeta } from "/codebuild/output/src2563597518/src/pages/creator/subscribe/opened/[creatorUserSn].vue?macro=true";
import { default as _91creatorUserSn_93NOQ7TNegKLMeta } from "/codebuild/output/src2563597518/src/pages/creator/subscribe/refund/[creatorUserSn].vue?macro=true";
import { default as _91creatorUserSn_93sbJb0TQswMMeta } from "/codebuild/output/src2563597518/src/pages/creator/subscribe/support/[creatorUserSn].vue?macro=true";
import { default as all2aFVXWwaRFMeta } from "/codebuild/output/src2563597518/src/pages/creators/all.vue?macro=true";
import { default as my1l5M1IQw1xMeta } from "/codebuild/output/src2563597518/src/pages/creators/my.vue?macro=true";
import { default as indexB9SetbBpKzMeta } from "/codebuild/output/src2563597518/src/pages/daily-check/index.vue?macro=true";
import { default as _91id_933Wuwg3EMMqMeta } from "/codebuild/output/src2563597518/src/pages/feed/[id].vue?macro=true";
import { default as indexOfwe4yv47iMeta } from "/codebuild/output/src2563597518/src/pages/feed/index.vue?macro=true";
import { default as indexZDcT1KMCFxMeta } from "/codebuild/output/src2563597518/src/pages/fund/gaudi-fund/index.vue?macro=true";
import { default as gaudicQR9zVNu1AMeta } from "/codebuild/output/src2563597518/src/pages/fund/gaudi.vue?macro=true";
import { default as _91id_93RiTkKiVUBtMeta } from "/codebuild/output/src2563597518/src/pages/fund/history/[id].vue?macro=true";
import { default as historyygVRUcOCOSMeta } from "/codebuild/output/src2563597518/src/pages/fund/history.vue?macro=true";
import { default as _91id_93cFBCdW9KVPMeta } from "/codebuild/output/src2563597518/src/pages/fund/index/[id].vue?macro=true";
import { default as indexvYXf3Bnc6fMeta } from "/codebuild/output/src2563597518/src/pages/fund/index.vue?macro=true";
import { default as _91id_937It34NwUYaMeta } from "/codebuild/output/src2563597518/src/pages/fund/notice/[id].vue?macro=true";
import { default as indexdegLZsOUFYMeta } from "/codebuild/output/src2563597518/src/pages/fund/notice/index.vue?macro=true";
import { default as _91id_93ZUivvsrdrLMeta } from "/codebuild/output/src2563597518/src/pages/hashtag/[id].vue?macro=true";
import { default as indexTNzmOMKI5tMeta } from "/codebuild/output/src2563597518/src/pages/hashtag/index.vue?macro=true";
import { default as indexFLqN6oFXGWMeta } from "/codebuild/output/src2563597518/src/pages/index.vue?macro=true";
import { default as _91id_93lQQ7MPFrMvMeta } from "/codebuild/output/src2563597518/src/pages/nova-plus/[id].vue?macro=true";
import { default as index8tcjz73E6EMeta } from "/codebuild/output/src2563597518/src/pages/nova-plus/index.vue?macro=true";
import { default as indexZPQenQijyRMeta } from "/codebuild/output/src2563597518/src/pages/oulim/index.vue?macro=true";
import { default as _91id_93E0IjTOAzTiMeta } from "/codebuild/output/src2563597518/src/pages/post/[id].vue?macro=true";
import { default as _64_91id_93sZ8eFvev6oMeta } from "/codebuild/output/src2563597518/src/pages/referral/@[id].vue?macro=true";
import { default as _91id_935gsMJb7PxjMeta } from "/codebuild/output/src2563597518/src/pages/search/detail/comment/[id].vue?macro=true";
import { default as index5mX7JcVKSDMeta } from "/codebuild/output/src2563597518/src/pages/search/detail/comment/index.vue?macro=true";
import { default as hashtagrnGVJ9sTVAMeta } from "/codebuild/output/src2563597518/src/pages/search/detail/hashtag.vue?macro=true";
import { default as _91id_93nfrzDbxnagMeta } from "/codebuild/output/src2563597518/src/pages/search/detail/index/[id].vue?macro=true";
import { default as indexCYYcPWXqOhMeta } from "/codebuild/output/src2563597518/src/pages/search/detail/index/index.vue?macro=true";
import { default as _91id_93PGx36rcywYMeta } from "/codebuild/output/src2563597518/src/pages/search/detail/nova-plus/[id].vue?macro=true";
import { default as indexaqTugP0d45Meta } from "/codebuild/output/src2563597518/src/pages/search/detail/nova-plus/index.vue?macro=true";
import { default as userTrknQaqB25Meta } from "/codebuild/output/src2563597518/src/pages/search/detail/user.vue?macro=true";
import { default as _91id_93dYwHa2ZVpqMeta } from "/codebuild/output/src2563597518/src/pages/search/index/[id].vue?macro=true";
import { default as indexWWp1Cp4OFnMeta } from "/codebuild/output/src2563597518/src/pages/search/index.vue?macro=true";
import { default as _91id_93LY0ZIOo1woMeta } from "/codebuild/output/src2563597518/src/pages/terms/[id].vue?macro=true";
import { default as _91postId_93j6y5cEtFROMeta } from "/codebuild/output/src2563597518/src/pages/user/[id]/activities/[postId].vue?macro=true";
import { default as activitiesQUsmVsI1eYMeta } from "/codebuild/output/src2563597518/src/pages/user/[id]/activities.vue?macro=true";
import { default as _91collectionId_93mVBsquUQ2YMeta } from "/codebuild/output/src2563597518/src/pages/user/[id]/collections/[collectionId].vue?macro=true";
import { default as collectionsWgIClrojrUMeta } from "/codebuild/output/src2563597518/src/pages/user/[id]/collections.vue?macro=true";
import { default as donationbRmyfN5ChKMeta } from "/codebuild/output/src2563597518/src/pages/user/[id]/donation.vue?macro=true";
import { default as _91postId_93cA4jgrYoheMeta } from "/codebuild/output/src2563597518/src/pages/user/[id]/index/[postId].vue?macro=true";
import { default as index499ANqQFumMeta } from "/codebuild/output/src2563597518/src/pages/user/[id]/index/index.vue?macro=true";
import { default as reward366XhI1KZPMeta } from "/codebuild/output/src2563597518/src/pages/user/[id]/reward.vue?macro=true";
import { default as indexToCllgcvNXMeta } from "/codebuild/output/src2563597518/src/pages/user/sponsors/index.vue?macro=true";
import { default as _91id_93MKSRYJJaaOMeta } from "/codebuild/output/src2563597518/src/pages/w3cf/detail/[id].vue?macro=true";
import { default as indexBQ92WT2Sk0Meta } from "/codebuild/output/src2563597518/src/pages/w3cf/index.vue?macro=true";
export default [
  {
    name: "apply-creator-apply___ko",
    path: "/apply-creator/apply",
    meta: applyJl9J2zMiUMMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-creator/apply.vue")
  },
  {
    name: "apply-creator-apply___en",
    path: "/en/apply-creator/apply",
    meta: applyJl9J2zMiUMMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-creator/apply.vue")
  },
  {
    name: "apply-creator-apply___th",
    path: "/th/apply-creator/apply",
    meta: applyJl9J2zMiUMMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-creator/apply.vue")
  },
  {
    name: "apply-creator___ko",
    path: "/apply-creator",
    meta: indexNwJGM9s1s3Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-creator/index.vue")
  },
  {
    name: "apply-creator___en",
    path: "/en/apply-creator",
    meta: indexNwJGM9s1s3Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-creator/index.vue")
  },
  {
    name: "apply-creator___th",
    path: "/th/apply-creator",
    meta: indexNwJGM9s1s3Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-creator/index.vue")
  },
  {
    name: "apply-dao-form___ko",
    path: "/apply-dao/form",
    meta: formofTXvdtbthMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-dao/form.vue")
  },
  {
    name: "apply-dao-form___en",
    path: "/en/apply-dao/form",
    meta: formofTXvdtbthMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-dao/form.vue")
  },
  {
    name: "apply-dao-form___th",
    path: "/th/apply-dao/form",
    meta: formofTXvdtbthMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-dao/form.vue")
  },
  {
    name: "apply-dao___ko",
    path: "/apply-dao",
    meta: indexdYfmjrOhtsMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-dao/index.vue")
  },
  {
    name: "apply-dao___en",
    path: "/en/apply-dao",
    meta: indexdYfmjrOhtsMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-dao/index.vue")
  },
  {
    name: "apply-dao___th",
    path: "/th/apply-dao",
    meta: indexdYfmjrOhtsMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-dao/index.vue")
  },
  {
    name: "apply-kpop-cover-dance-form___ko",
    path: "/apply-kpop-cover-dance/form",
    meta: formCu4QrGcbRnMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-kpop-cover-dance/form.vue")
  },
  {
    name: "apply-kpop-cover-dance-form___en",
    path: "/en/apply-kpop-cover-dance/form",
    meta: formCu4QrGcbRnMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-kpop-cover-dance/form.vue")
  },
  {
    name: "apply-kpop-cover-dance-form___th",
    path: "/th/apply-kpop-cover-dance/form",
    meta: formCu4QrGcbRnMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-kpop-cover-dance/form.vue")
  },
  {
    name: "apply-rent-facility-id-additional___ko",
    path: "/apply-rent/facility-:id()/additional",
    meta: additionalSMnhPWirAsMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-rent/facility-[id]/additional.vue")
  },
  {
    name: "apply-rent-facility-id-additional___en",
    path: "/en/apply-rent/facility-:id()/additional",
    meta: additionalSMnhPWirAsMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-rent/facility-[id]/additional.vue")
  },
  {
    name: "apply-rent-facility-id-additional___th",
    path: "/th/apply-rent/facility-:id()/additional",
    meta: additionalSMnhPWirAsMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-rent/facility-[id]/additional.vue")
  },
  {
    name: "apply-rent-facility-id-complete___ko",
    path: "/apply-rent/facility-:id()/complete",
    meta: completeHo65W8Jc06Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-rent/facility-[id]/complete.vue")
  },
  {
    name: "apply-rent-facility-id-complete___en",
    path: "/en/apply-rent/facility-:id()/complete",
    meta: completeHo65W8Jc06Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-rent/facility-[id]/complete.vue")
  },
  {
    name: "apply-rent-facility-id-complete___th",
    path: "/th/apply-rent/facility-:id()/complete",
    meta: completeHo65W8Jc06Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-rent/facility-[id]/complete.vue")
  },
  {
    name: "apply-rent-facility-id___ko",
    path: "/apply-rent/facility-:id()",
    meta: index9XX9R7eiGTMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-rent/facility-[id]/index.vue")
  },
  {
    name: "apply-rent-facility-id___en",
    path: "/en/apply-rent/facility-:id()",
    meta: index9XX9R7eiGTMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-rent/facility-[id]/index.vue")
  },
  {
    name: "apply-rent-facility-id___th",
    path: "/th/apply-rent/facility-:id()",
    meta: index9XX9R7eiGTMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-rent/facility-[id]/index.vue")
  },
  {
    name: "apply-rent___ko",
    path: "/apply-rent",
    meta: indexLPEYxcXZY1Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-rent/index.vue")
  },
  {
    name: "apply-rent___en",
    path: "/en/apply-rent",
    meta: indexLPEYxcXZY1Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-rent/index.vue")
  },
  {
    name: "apply-rent___th",
    path: "/th/apply-rent",
    meta: indexLPEYxcXZY1Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-rent/index.vue")
  },
  {
    name: "apply-super-moon-form___ko",
    path: "/apply-super-moon/form",
    meta: form4WI7z6y6CzMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-super-moon/form.vue")
  },
  {
    name: "apply-super-moon-form___en",
    path: "/en/apply-super-moon/form",
    meta: form4WI7z6y6CzMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-super-moon/form.vue")
  },
  {
    name: "apply-super-moon-form___th",
    path: "/th/apply-super-moon/form",
    meta: form4WI7z6y6CzMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-super-moon/form.vue")
  },
  {
    name: "apply-super-moon___ko",
    path: "/apply-super-moon",
    meta: indexZiCjQpScknMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-super-moon/index.vue")
  },
  {
    name: "apply-super-moon___en",
    path: "/en/apply-super-moon",
    meta: indexZiCjQpScknMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-super-moon/index.vue")
  },
  {
    name: "apply-super-moon___th",
    path: "/th/apply-super-moon",
    meta: indexZiCjQpScknMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/apply-super-moon/index.vue")
  },
  {
    name: "board-event-id___ko",
    path: "/board/event/:id()",
    meta: _91id_931fY9ATL6LmMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/event/[id].vue")
  },
  {
    name: "board-event-id___en",
    path: "/en/board/event/:id()",
    meta: _91id_931fY9ATL6LmMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/event/[id].vue")
  },
  {
    name: "board-event-id___th",
    path: "/th/board/event/:id()",
    meta: _91id_931fY9ATL6LmMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/event/[id].vue")
  },
  {
    name: "board-event___ko",
    path: "/board/event",
    meta: indexSQQLMglvn2Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/event/index.vue")
  },
  {
    name: "board-event___en",
    path: "/en/board/event",
    meta: indexSQQLMglvn2Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/event/index.vue")
  },
  {
    name: "board-event___th",
    path: "/th/board/event",
    meta: indexSQQLMglvn2Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/event/index.vue")
  },
  {
    name: "board-faq-id___ko",
    path: "/board/faq/:id()",
    meta: _91id_93OE0Smtfm6LMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/faq/[id].vue")
  },
  {
    name: "board-faq-id___en",
    path: "/en/board/faq/:id()",
    meta: _91id_93OE0Smtfm6LMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/faq/[id].vue")
  },
  {
    name: "board-faq-id___th",
    path: "/th/board/faq/:id()",
    meta: _91id_93OE0Smtfm6LMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/faq/[id].vue")
  },
  {
    name: "board-faq___ko",
    path: "/board/faq",
    meta: indexSwoHWkmeCtMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/faq/index.vue")
  },
  {
    name: "board-faq___en",
    path: "/en/board/faq",
    meta: indexSwoHWkmeCtMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/faq/index.vue")
  },
  {
    name: "board-faq___th",
    path: "/th/board/faq",
    meta: indexSwoHWkmeCtMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/faq/index.vue")
  },
  {
    name: "board-news-id___ko",
    path: "/board/news/:id()",
    meta: _91id_93YOIzoP25l0Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/news/[id].vue")
  },
  {
    name: "board-news-id___en",
    path: "/en/board/news/:id()",
    meta: _91id_93YOIzoP25l0Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/news/[id].vue")
  },
  {
    name: "board-news-id___th",
    path: "/th/board/news/:id()",
    meta: _91id_93YOIzoP25l0Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/news/[id].vue")
  },
  {
    name: "board-news___ko",
    path: "/board/news",
    meta: index94YGWYUWxsMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/news/index.vue")
  },
  {
    name: "board-news___en",
    path: "/en/board/news",
    meta: index94YGWYUWxsMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/news/index.vue")
  },
  {
    name: "board-news___th",
    path: "/th/board/news",
    meta: index94YGWYUWxsMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/news/index.vue")
  },
  {
    name: "board-notice-id___ko",
    path: "/board/notice/:id()",
    meta: _91id_932sXlFWHkvNMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/notice/[id].vue")
  },
  {
    name: "board-notice-id___en",
    path: "/en/board/notice/:id()",
    meta: _91id_932sXlFWHkvNMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/notice/[id].vue")
  },
  {
    name: "board-notice-id___th",
    path: "/th/board/notice/:id()",
    meta: _91id_932sXlFWHkvNMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/notice/[id].vue")
  },
  {
    name: "board-notice___ko",
    path: "/board/notice",
    meta: indexsszPPtiPItMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/notice/index.vue")
  },
  {
    name: "board-notice___en",
    path: "/en/board/notice",
    meta: indexsszPPtiPItMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/notice/index.vue")
  },
  {
    name: "board-notice___th",
    path: "/th/board/notice",
    meta: indexsszPPtiPItMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/board/notice/index.vue")
  },
  {
    name: "callback-pay___ko",
    path: "/callback/pay",
    meta: paygweABX79v5Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/callback/pay.vue")
  },
  {
    name: "callback-pay___en",
    path: "/en/callback/pay",
    meta: paygweABX79v5Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/callback/pay.vue")
  },
  {
    name: "callback-pay___th",
    path: "/th/callback/pay",
    meta: paygweABX79v5Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/callback/pay.vue")
  },
  {
    name: "callback-signin___ko",
    path: "/callback/signin",
    meta: signinSZ7PHPa9P9Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/callback/signin.vue")
  },
  {
    name: "callback-signin___en",
    path: "/en/callback/signin",
    meta: signinSZ7PHPa9P9Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/callback/signin.vue")
  },
  {
    name: "callback-signin___th",
    path: "/th/callback/signin",
    meta: signinSZ7PHPa9P9Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/callback/signin.vue")
  },
  {
    name: "callback-signup___ko",
    path: "/callback/signup",
    meta: signupzkUPoAaNZAMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/callback/signup.vue")
  },
  {
    name: "callback-signup___en",
    path: "/en/callback/signup",
    meta: signupzkUPoAaNZAMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/callback/signup.vue")
  },
  {
    name: "callback-signup___th",
    path: "/th/callback/signup",
    meta: signupzkUPoAaNZAMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/callback/signup.vue")
  },
  {
    name: "creator-id-activities___ko",
    path: "/creator/:id()/activities",
    meta: activitiesPcKgJDrSn7Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/activities.vue"),
    children: [
  {
    name: "creator-id-activities-postId___ko",
    path: ":postId()",
    meta: _91postId_93Gobwb93sxkMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "creator-id-activities___en",
    path: "/en/creator/:id()/activities",
    meta: activitiesPcKgJDrSn7Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/activities.vue"),
    children: [
  {
    name: "creator-id-activities-postId___en",
    path: ":postId()",
    meta: _91postId_93Gobwb93sxkMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "creator-id-activities___th",
    path: "/th/creator/:id()/activities",
    meta: activitiesPcKgJDrSn7Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/activities.vue"),
    children: [
  {
    name: "creator-id-activities-postId___th",
    path: ":postId()",
    meta: _91postId_93Gobwb93sxkMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "creator-id-collections___ko",
    path: "/creator/:id()/collections",
    meta: collections0MRLnojiwMMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/collections.vue"),
    children: [
  {
    name: "creator-id-collections-collectionId___ko",
    path: ":collectionId()",
    meta: _91collectionId_93HiDUpkXgupMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "creator-id-collections___en",
    path: "/en/creator/:id()/collections",
    meta: collections0MRLnojiwMMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/collections.vue"),
    children: [
  {
    name: "creator-id-collections-collectionId___en",
    path: ":collectionId()",
    meta: _91collectionId_93HiDUpkXgupMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "creator-id-collections___th",
    path: "/th/creator/:id()/collections",
    meta: collections0MRLnojiwMMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/collections.vue"),
    children: [
  {
    name: "creator-id-collections-collectionId___th",
    path: ":collectionId()",
    meta: _91collectionId_93HiDUpkXgupMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "creator-id-dao-board-daoPostId___ko",
    path: "/creator/:id()/dao/board/:daoPostId()",
    meta: _91daoPostId_9330jybtzAjdMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/dao/board/[daoPostId].vue")
  },
  {
    name: "creator-id-dao-board-daoPostId___en",
    path: "/en/creator/:id()/dao/board/:daoPostId()",
    meta: _91daoPostId_9330jybtzAjdMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/dao/board/[daoPostId].vue")
  },
  {
    name: "creator-id-dao-board-daoPostId___th",
    path: "/th/creator/:id()/dao/board/:daoPostId()",
    meta: _91daoPostId_9330jybtzAjdMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/dao/board/[daoPostId].vue")
  },
  {
    name: "creator-id-dao-board___ko",
    path: "/creator/:id()/dao/board",
    meta: indexXmi9TBLRmdMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/dao/board/index.vue")
  },
  {
    name: "creator-id-dao-board___en",
    path: "/en/creator/:id()/dao/board",
    meta: indexXmi9TBLRmdMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/dao/board/index.vue")
  },
  {
    name: "creator-id-dao-board___th",
    path: "/th/creator/:id()/dao/board",
    meta: indexXmi9TBLRmdMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/dao/board/index.vue")
  },
  {
    name: "creator-id-dao___ko",
    path: "/creator/:id()/dao",
    meta: indexQ86N3lJcdPMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/dao/index.vue")
  },
  {
    name: "creator-id-dao___en",
    path: "/en/creator/:id()/dao",
    meta: indexQ86N3lJcdPMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/dao/index.vue")
  },
  {
    name: "creator-id-dao___th",
    path: "/th/creator/:id()/dao",
    meta: indexQ86N3lJcdPMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/dao/index.vue")
  },
  {
    name: "creator-id-dao-member___ko",
    path: "/creator/:id()/dao/member",
    meta: membermwABYV279HMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/dao/member.vue")
  },
  {
    name: "creator-id-dao-member___en",
    path: "/en/creator/:id()/dao/member",
    meta: membermwABYV279HMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/dao/member.vue")
  },
  {
    name: "creator-id-dao-member___th",
    path: "/th/creator/:id()/dao/member",
    meta: membermwABYV279HMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/dao/member.vue")
  },
  {
    name: "creator-id-dao-vote___ko",
    path: "/creator/:id()/dao/vote",
    meta: voteIx6JBl0kxAMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/dao/vote.vue"),
    children: [
  {
    name: "creator-id-dao-vote-voteId___ko",
    path: ":voteId()",
    meta: _91voteId_93S9TUuqwl82Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/dao/vote/[voteId].vue")
  }
]
  },
  {
    name: "creator-id-dao-vote___en",
    path: "/en/creator/:id()/dao/vote",
    meta: voteIx6JBl0kxAMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/dao/vote.vue"),
    children: [
  {
    name: "creator-id-dao-vote-voteId___en",
    path: ":voteId()",
    meta: _91voteId_93S9TUuqwl82Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/dao/vote/[voteId].vue")
  }
]
  },
  {
    name: "creator-id-dao-vote___th",
    path: "/th/creator/:id()/dao/vote",
    meta: voteIx6JBl0kxAMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/dao/vote.vue"),
    children: [
  {
    name: "creator-id-dao-vote-voteId___th",
    path: ":voteId()",
    meta: _91voteId_93S9TUuqwl82Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/dao/vote/[voteId].vue")
  }
]
  },
  {
    name: "creator-id-donation___ko",
    path: "/creator/:id()/donation",
    meta: donationZGMrDU5YbFMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/donation.vue")
  },
  {
    name: "creator-id-donation___en",
    path: "/en/creator/:id()/donation",
    meta: donationZGMrDU5YbFMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/donation.vue")
  },
  {
    name: "creator-id-donation___th",
    path: "/th/creator/:id()/donation",
    meta: donationZGMrDU5YbFMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/donation.vue")
  },
  {
    name: "creator-id-guestbook___ko",
    path: "/creator/:id()/guestbook",
    meta: guestbooksebQn1fsQAMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/guestbook.vue")
  },
  {
    name: "creator-id-guestbook___en",
    path: "/en/creator/:id()/guestbook",
    meta: guestbooksebQn1fsQAMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/guestbook.vue")
  },
  {
    name: "creator-id-guestbook___th",
    path: "/th/creator/:id()/guestbook",
    meta: guestbooksebQn1fsQAMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/guestbook.vue")
  },
  {
    name: "creator-id___ko",
    path: "/creator/:id()",
    meta: indexXP28758SwtMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/index.vue"),
    children: [
  {
    name: "creator-id-index-postId___ko",
    path: ":postId()",
    meta: _91postId_93WikFdIOxuxMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/index/[postId].vue")
  }
]
  },
  {
    name: "creator-id___en",
    path: "/en/creator/:id()",
    meta: indexXP28758SwtMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/index.vue"),
    children: [
  {
    name: "creator-id-index-postId___en",
    path: ":postId()",
    meta: _91postId_93WikFdIOxuxMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/index/[postId].vue")
  }
]
  },
  {
    name: "creator-id___th",
    path: "/th/creator/:id()",
    meta: indexXP28758SwtMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/index.vue"),
    children: [
  {
    name: "creator-id-index-postId___th",
    path: ":postId()",
    meta: _91postId_93WikFdIOxuxMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/index/[postId].vue")
  }
]
  },
  {
    name: "creator-id-notice___ko",
    path: "/creator/:id()/notice",
    meta: notice8pvCv2k5LLMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/notice.vue"),
    children: [
  {
    name: "creator-id-notice-noticeId___ko",
    path: ":noticeId()",
    meta: _91noticeId_93W9uWo5yIXiMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/notice/[noticeId].vue")
  }
]
  },
  {
    name: "creator-id-notice___en",
    path: "/en/creator/:id()/notice",
    meta: notice8pvCv2k5LLMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/notice.vue"),
    children: [
  {
    name: "creator-id-notice-noticeId___en",
    path: ":noticeId()",
    meta: _91noticeId_93W9uWo5yIXiMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/notice/[noticeId].vue")
  }
]
  },
  {
    name: "creator-id-notice___th",
    path: "/th/creator/:id()/notice",
    meta: notice8pvCv2k5LLMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/notice.vue"),
    children: [
  {
    name: "creator-id-notice-noticeId___th",
    path: ":noticeId()",
    meta: _91noticeId_93W9uWo5yIXiMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/notice/[noticeId].vue")
  }
]
  },
  {
    name: "creator-id-nova-plus-postId___ko",
    path: "/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93AS1Vqkt6SiMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/nova-plus/[postId].vue")
  },
  {
    name: "creator-id-nova-plus-postId___en",
    path: "/en/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93AS1Vqkt6SiMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/nova-plus/[postId].vue")
  },
  {
    name: "creator-id-nova-plus-postId___th",
    path: "/th/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93AS1Vqkt6SiMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/nova-plus/[postId].vue")
  },
  {
    name: "creator-id-nova-plus___ko",
    path: "/creator/:id()/nova-plus",
    meta: indexVlPG1lynjGMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/nova-plus/index.vue")
  },
  {
    name: "creator-id-nova-plus___en",
    path: "/en/creator/:id()/nova-plus",
    meta: indexVlPG1lynjGMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/nova-plus/index.vue")
  },
  {
    name: "creator-id-nova-plus___th",
    path: "/th/creator/:id()/nova-plus",
    meta: indexVlPG1lynjGMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/nova-plus/index.vue")
  },
  {
    name: "creator-id-posts-postId___ko",
    path: "/creator/:id()/posts/:postId()",
    meta: _91postId_93My4ySasZzkMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/posts/[postId].vue")
  },
  {
    name: "creator-id-posts-postId___en",
    path: "/en/creator/:id()/posts/:postId()",
    meta: _91postId_93My4ySasZzkMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/posts/[postId].vue")
  },
  {
    name: "creator-id-posts-postId___th",
    path: "/th/creator/:id()/posts/:postId()",
    meta: _91postId_93My4ySasZzkMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/posts/[postId].vue")
  },
  {
    name: "creator-id-posts___ko",
    path: "/creator/:id()/posts",
    meta: indexEFwYqWZBDzMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/posts/index.vue")
  },
  {
    name: "creator-id-posts___en",
    path: "/en/creator/:id()/posts",
    meta: indexEFwYqWZBDzMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/posts/index.vue")
  },
  {
    name: "creator-id-posts___th",
    path: "/th/creator/:id()/posts",
    meta: indexEFwYqWZBDzMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/posts/index.vue")
  },
  {
    name: "creator-id-reward___ko",
    path: "/creator/:id()/reward",
    meta: reward5GnYmss0wVMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/reward.vue")
  },
  {
    name: "creator-id-reward___en",
    path: "/en/creator/:id()/reward",
    meta: reward5GnYmss0wVMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/reward.vue")
  },
  {
    name: "creator-id-reward___th",
    path: "/th/creator/:id()/reward",
    meta: reward5GnYmss0wVMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/reward.vue")
  },
  {
    name: "creator-id-statistics___ko",
    path: "/creator/:id()/statistics",
    meta: statisticsyTbdrLmThNMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/statistics.vue")
  },
  {
    name: "creator-id-statistics___en",
    path: "/en/creator/:id()/statistics",
    meta: statisticsyTbdrLmThNMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/statistics.vue")
  },
  {
    name: "creator-id-statistics___th",
    path: "/th/creator/:id()/statistics",
    meta: statisticsyTbdrLmThNMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/[id]/statistics.vue")
  },
  {
    name: "creator-sponsors___ko",
    path: "/creator/sponsors",
    meta: sponsorsKkfZaP0d95Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/sponsors.vue")
  },
  {
    name: "creator-sponsors___en",
    path: "/en/creator/sponsors",
    meta: sponsorsKkfZaP0d95Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/sponsors.vue")
  },
  {
    name: "creator-sponsors___th",
    path: "/th/creator/sponsors",
    meta: sponsorsKkfZaP0d95Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/sponsors.vue")
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___ko",
    path: "/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_93WmXCwkAsr4Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/subscribe/cancel/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___en",
    path: "/en/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_93WmXCwkAsr4Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/subscribe/cancel/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___th",
    path: "/th/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_93WmXCwkAsr4Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/subscribe/cancel/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___ko",
    path: "/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93j5sSzexW0DMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/subscribe/opened/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___en",
    path: "/en/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93j5sSzexW0DMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/subscribe/opened/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___th",
    path: "/th/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93j5sSzexW0DMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/subscribe/opened/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___ko",
    path: "/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_93NOQ7TNegKLMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/subscribe/refund/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___en",
    path: "/en/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_93NOQ7TNegKLMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/subscribe/refund/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___th",
    path: "/th/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_93NOQ7TNegKLMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/subscribe/refund/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-support-creatorUserSn___ko",
    path: "/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_93sbJb0TQswMMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/subscribe/support/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-support-creatorUserSn___en",
    path: "/en/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_93sbJb0TQswMMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/subscribe/support/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-support-creatorUserSn___th",
    path: "/th/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_93sbJb0TQswMMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creator/subscribe/support/[creatorUserSn].vue")
  },
  {
    name: "creators-all___ko",
    path: "/creators/all",
    meta: all2aFVXWwaRFMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creators/all.vue")
  },
  {
    name: "creators-all___en",
    path: "/en/creators/all",
    meta: all2aFVXWwaRFMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creators/all.vue")
  },
  {
    name: "creators-all___th",
    path: "/th/creators/all",
    meta: all2aFVXWwaRFMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creators/all.vue")
  },
  {
    name: "creators-my___ko",
    path: "/creators/my",
    meta: my1l5M1IQw1xMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creators/my.vue")
  },
  {
    name: "creators-my___en",
    path: "/en/creators/my",
    meta: my1l5M1IQw1xMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creators/my.vue")
  },
  {
    name: "creators-my___th",
    path: "/th/creators/my",
    meta: my1l5M1IQw1xMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/creators/my.vue")
  },
  {
    name: "daily-check___ko",
    path: "/daily-check",
    meta: indexB9SetbBpKzMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/daily-check/index.vue")
  },
  {
    name: "daily-check___en",
    path: "/en/daily-check",
    meta: indexB9SetbBpKzMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/daily-check/index.vue")
  },
  {
    name: "daily-check___th",
    path: "/th/daily-check",
    meta: indexB9SetbBpKzMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/daily-check/index.vue")
  },
  {
    name: "feed-id___ko",
    path: "/feed/:id()",
    component: () => import("/codebuild/output/src2563597518/src/pages/feed/[id].vue")
  },
  {
    name: "feed-id___en",
    path: "/en/feed/:id()",
    component: () => import("/codebuild/output/src2563597518/src/pages/feed/[id].vue")
  },
  {
    name: "feed-id___th",
    path: "/th/feed/:id()",
    component: () => import("/codebuild/output/src2563597518/src/pages/feed/[id].vue")
  },
  {
    name: "feed___ko",
    path: "/feed",
    component: () => import("/codebuild/output/src2563597518/src/pages/feed/index.vue")
  },
  {
    name: "feed___en",
    path: "/en/feed",
    component: () => import("/codebuild/output/src2563597518/src/pages/feed/index.vue")
  },
  {
    name: "feed___th",
    path: "/th/feed",
    component: () => import("/codebuild/output/src2563597518/src/pages/feed/index.vue")
  },
  {
    name: "fund-gaudi-fund___ko",
    path: "/fund/gaudi-fund",
    meta: indexZDcT1KMCFxMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/gaudi-fund/index.vue")
  },
  {
    name: "fund-gaudi-fund___en",
    path: "/en/fund/gaudi-fund",
    meta: indexZDcT1KMCFxMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/gaudi-fund/index.vue")
  },
  {
    name: "fund-gaudi-fund___th",
    path: "/th/fund/gaudi-fund",
    meta: indexZDcT1KMCFxMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/gaudi-fund/index.vue")
  },
  {
    name: "fund-gaudi___ko",
    path: "/fund/gaudi",
    meta: gaudicQR9zVNu1AMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/gaudi.vue")
  },
  {
    name: "fund-gaudi___en",
    path: "/en/fund/gaudi",
    meta: gaudicQR9zVNu1AMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/gaudi.vue")
  },
  {
    name: "fund-gaudi___th",
    path: "/th/fund/gaudi",
    meta: gaudicQR9zVNu1AMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/gaudi.vue")
  },
  {
    name: "fund-history___ko",
    path: "/fund/history",
    meta: historyygVRUcOCOSMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/history.vue"),
    children: [
  {
    name: "fund-history-id___ko",
    path: ":id()",
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/history/[id].vue")
  }
]
  },
  {
    name: "fund-history___en",
    path: "/en/fund/history",
    meta: historyygVRUcOCOSMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/history.vue"),
    children: [
  {
    name: "fund-history-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/history/[id].vue")
  }
]
  },
  {
    name: "fund-history___th",
    path: "/th/fund/history",
    meta: historyygVRUcOCOSMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/history.vue"),
    children: [
  {
    name: "fund-history-id___th",
    path: ":id()",
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/history/[id].vue")
  }
]
  },
  {
    name: "fund___ko",
    path: "/fund",
    meta: indexvYXf3Bnc6fMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/index.vue"),
    children: [
  {
    name: "fund-index-id___ko",
    path: ":id()",
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/index/[id].vue")
  }
]
  },
  {
    name: "fund___en",
    path: "/en/fund",
    meta: indexvYXf3Bnc6fMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/index.vue"),
    children: [
  {
    name: "fund-index-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/index/[id].vue")
  }
]
  },
  {
    name: "fund___th",
    path: "/th/fund",
    meta: indexvYXf3Bnc6fMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/index.vue"),
    children: [
  {
    name: "fund-index-id___th",
    path: ":id()",
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/index/[id].vue")
  }
]
  },
  {
    name: "fund-notice-id___ko",
    path: "/fund/notice/:id()",
    meta: _91id_937It34NwUYaMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/notice/[id].vue")
  },
  {
    name: "fund-notice-id___en",
    path: "/en/fund/notice/:id()",
    meta: _91id_937It34NwUYaMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/notice/[id].vue")
  },
  {
    name: "fund-notice-id___th",
    path: "/th/fund/notice/:id()",
    meta: _91id_937It34NwUYaMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/notice/[id].vue")
  },
  {
    name: "fund-notice___ko",
    path: "/fund/notice",
    meta: indexdegLZsOUFYMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/notice/index.vue")
  },
  {
    name: "fund-notice___en",
    path: "/en/fund/notice",
    meta: indexdegLZsOUFYMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/notice/index.vue")
  },
  {
    name: "fund-notice___th",
    path: "/th/fund/notice",
    meta: indexdegLZsOUFYMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/fund/notice/index.vue")
  },
  {
    name: "hashtag-id___ko",
    path: "/hashtag/:id()",
    meta: _91id_93ZUivvsrdrLMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/hashtag/[id].vue")
  },
  {
    name: "hashtag-id___en",
    path: "/en/hashtag/:id()",
    meta: _91id_93ZUivvsrdrLMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/hashtag/[id].vue")
  },
  {
    name: "hashtag-id___th",
    path: "/th/hashtag/:id()",
    meta: _91id_93ZUivvsrdrLMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/hashtag/[id].vue")
  },
  {
    name: "hashtag___ko",
    path: "/hashtag",
    meta: indexTNzmOMKI5tMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/hashtag/index.vue")
  },
  {
    name: "hashtag___en",
    path: "/en/hashtag",
    meta: indexTNzmOMKI5tMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/hashtag/index.vue")
  },
  {
    name: "hashtag___th",
    path: "/th/hashtag",
    meta: indexTNzmOMKI5tMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/hashtag/index.vue")
  },
  {
    name: "index___ko",
    path: "/",
    meta: indexFLqN6oFXGWMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexFLqN6oFXGWMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/index.vue")
  },
  {
    name: "index___th",
    path: "/th",
    meta: indexFLqN6oFXGWMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/index.vue")
  },
  {
    name: "nova-plus-id___ko",
    path: "/nova-plus/:id()",
    meta: _91id_93lQQ7MPFrMvMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/nova-plus/[id].vue")
  },
  {
    name: "nova-plus-id___en",
    path: "/en/nova-plus/:id()",
    meta: _91id_93lQQ7MPFrMvMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/nova-plus/[id].vue")
  },
  {
    name: "nova-plus-id___th",
    path: "/th/nova-plus/:id()",
    meta: _91id_93lQQ7MPFrMvMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/nova-plus/[id].vue")
  },
  {
    name: "nova-plus___ko",
    path: "/nova-plus",
    meta: index8tcjz73E6EMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/nova-plus/index.vue")
  },
  {
    name: "nova-plus___en",
    path: "/en/nova-plus",
    meta: index8tcjz73E6EMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/nova-plus/index.vue")
  },
  {
    name: "nova-plus___th",
    path: "/th/nova-plus",
    meta: index8tcjz73E6EMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/nova-plus/index.vue")
  },
  {
    name: "oulim___ko",
    path: "/oulim",
    meta: indexZPQenQijyRMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/oulim/index.vue")
  },
  {
    name: "oulim___en",
    path: "/en/oulim",
    meta: indexZPQenQijyRMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/oulim/index.vue")
  },
  {
    name: "oulim___th",
    path: "/th/oulim",
    meta: indexZPQenQijyRMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/oulim/index.vue")
  },
  {
    name: "post-id___ko",
    path: "/post/:id()",
    meta: _91id_93E0IjTOAzTiMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/post/[id].vue")
  },
  {
    name: "post-id___en",
    path: "/en/post/:id()",
    meta: _91id_93E0IjTOAzTiMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/post/[id].vue")
  },
  {
    name: "post-id___th",
    path: "/th/post/:id()",
    meta: _91id_93E0IjTOAzTiMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/post/[id].vue")
  },
  {
    name: "referral-@id___ko",
    path: "/referral/@:id()",
    meta: _64_91id_93sZ8eFvev6oMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/referral/@[id].vue")
  },
  {
    name: "referral-@id___en",
    path: "/en/referral/@:id()",
    meta: _64_91id_93sZ8eFvev6oMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/referral/@[id].vue")
  },
  {
    name: "referral-@id___th",
    path: "/th/referral/@:id()",
    meta: _64_91id_93sZ8eFvev6oMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/referral/@[id].vue")
  },
  {
    name: "search-detail-comment-id___ko",
    path: "/search/detail/comment/:id()",
    meta: _91id_935gsMJb7PxjMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/comment/[id].vue")
  },
  {
    name: "search-detail-comment-id___en",
    path: "/en/search/detail/comment/:id()",
    meta: _91id_935gsMJb7PxjMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/comment/[id].vue")
  },
  {
    name: "search-detail-comment-id___th",
    path: "/th/search/detail/comment/:id()",
    meta: _91id_935gsMJb7PxjMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/comment/[id].vue")
  },
  {
    name: "search-detail-comment___ko",
    path: "/search/detail/comment",
    meta: index5mX7JcVKSDMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/comment/index.vue")
  },
  {
    name: "search-detail-comment___en",
    path: "/en/search/detail/comment",
    meta: index5mX7JcVKSDMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/comment/index.vue")
  },
  {
    name: "search-detail-comment___th",
    path: "/th/search/detail/comment",
    meta: index5mX7JcVKSDMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/comment/index.vue")
  },
  {
    name: "search-detail-hashtag___ko",
    path: "/search/detail/hashtag",
    meta: hashtagrnGVJ9sTVAMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/hashtag.vue")
  },
  {
    name: "search-detail-hashtag___en",
    path: "/en/search/detail/hashtag",
    meta: hashtagrnGVJ9sTVAMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/hashtag.vue")
  },
  {
    name: "search-detail-hashtag___th",
    path: "/th/search/detail/hashtag",
    meta: hashtagrnGVJ9sTVAMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/hashtag.vue")
  },
  {
    name: "search-detail-index-id___ko",
    path: "/search/detail/:id()",
    meta: _91id_93nfrzDbxnagMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/index/[id].vue")
  },
  {
    name: "search-detail-index-id___en",
    path: "/en/search/detail/:id()",
    meta: _91id_93nfrzDbxnagMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/index/[id].vue")
  },
  {
    name: "search-detail-index-id___th",
    path: "/th/search/detail/:id()",
    meta: _91id_93nfrzDbxnagMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/index/[id].vue")
  },
  {
    name: "search-detail-index___ko",
    path: "/search/detail",
    meta: indexCYYcPWXqOhMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/index/index.vue")
  },
  {
    name: "search-detail-index___en",
    path: "/en/search/detail",
    meta: indexCYYcPWXqOhMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/index/index.vue")
  },
  {
    name: "search-detail-index___th",
    path: "/th/search/detail",
    meta: indexCYYcPWXqOhMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/index/index.vue")
  },
  {
    name: "search-detail-nova-plus-id___ko",
    path: "/search/detail/nova-plus/:id()",
    meta: _91id_93PGx36rcywYMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/nova-plus/[id].vue")
  },
  {
    name: "search-detail-nova-plus-id___en",
    path: "/en/search/detail/nova-plus/:id()",
    meta: _91id_93PGx36rcywYMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/nova-plus/[id].vue")
  },
  {
    name: "search-detail-nova-plus-id___th",
    path: "/th/search/detail/nova-plus/:id()",
    meta: _91id_93PGx36rcywYMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/nova-plus/[id].vue")
  },
  {
    name: "search-detail-nova-plus___ko",
    path: "/search/detail/nova-plus",
    meta: indexaqTugP0d45Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/nova-plus/index.vue")
  },
  {
    name: "search-detail-nova-plus___en",
    path: "/en/search/detail/nova-plus",
    meta: indexaqTugP0d45Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/nova-plus/index.vue")
  },
  {
    name: "search-detail-nova-plus___th",
    path: "/th/search/detail/nova-plus",
    meta: indexaqTugP0d45Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/nova-plus/index.vue")
  },
  {
    name: "search-detail-user___ko",
    path: "/search/detail/user",
    meta: userTrknQaqB25Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/user.vue")
  },
  {
    name: "search-detail-user___en",
    path: "/en/search/detail/user",
    meta: userTrknQaqB25Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/user.vue")
  },
  {
    name: "search-detail-user___th",
    path: "/th/search/detail/user",
    meta: userTrknQaqB25Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/detail/user.vue")
  },
  {
    name: "search___ko",
    path: "/search",
    meta: indexWWp1Cp4OFnMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/index.vue"),
    children: [
  {
    name: "search-index-id___ko",
    path: ":id()",
    meta: _91id_93dYwHa2ZVpqMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/index/[id].vue")
  }
]
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: indexWWp1Cp4OFnMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/index.vue"),
    children: [
  {
    name: "search-index-id___en",
    path: ":id()",
    meta: _91id_93dYwHa2ZVpqMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/index/[id].vue")
  }
]
  },
  {
    name: "search___th",
    path: "/th/search",
    meta: indexWWp1Cp4OFnMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/index.vue"),
    children: [
  {
    name: "search-index-id___th",
    path: ":id()",
    meta: _91id_93dYwHa2ZVpqMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/search/index/[id].vue")
  }
]
  },
  {
    name: "terms-id___ko",
    path: "/terms/:id()",
    meta: _91id_93LY0ZIOo1woMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/terms/[id].vue")
  },
  {
    name: "terms-id___en",
    path: "/en/terms/:id()",
    meta: _91id_93LY0ZIOo1woMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/terms/[id].vue")
  },
  {
    name: "terms-id___th",
    path: "/th/terms/:id()",
    meta: _91id_93LY0ZIOo1woMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/terms/[id].vue")
  },
  {
    name: "user-id-activities___ko",
    path: "/user/:id()/activities",
    meta: activitiesQUsmVsI1eYMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/activities.vue"),
    children: [
  {
    name: "user-id-activities-postId___ko",
    path: ":postId()",
    meta: _91postId_93j6y5cEtFROMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "user-id-activities___en",
    path: "/en/user/:id()/activities",
    meta: activitiesQUsmVsI1eYMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/activities.vue"),
    children: [
  {
    name: "user-id-activities-postId___en",
    path: ":postId()",
    meta: _91postId_93j6y5cEtFROMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "user-id-activities___th",
    path: "/th/user/:id()/activities",
    meta: activitiesQUsmVsI1eYMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/activities.vue"),
    children: [
  {
    name: "user-id-activities-postId___th",
    path: ":postId()",
    meta: _91postId_93j6y5cEtFROMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "user-id-collections___ko",
    path: "/user/:id()/collections",
    meta: collectionsWgIClrojrUMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/collections.vue"),
    children: [
  {
    name: "user-id-collections-collectionId___ko",
    path: ":collectionId()",
    meta: _91collectionId_93mVBsquUQ2YMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "user-id-collections___en",
    path: "/en/user/:id()/collections",
    meta: collectionsWgIClrojrUMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/collections.vue"),
    children: [
  {
    name: "user-id-collections-collectionId___en",
    path: ":collectionId()",
    meta: _91collectionId_93mVBsquUQ2YMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "user-id-collections___th",
    path: "/th/user/:id()/collections",
    meta: collectionsWgIClrojrUMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/collections.vue"),
    children: [
  {
    name: "user-id-collections-collectionId___th",
    path: ":collectionId()",
    meta: _91collectionId_93mVBsquUQ2YMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "user-id-donation___ko",
    path: "/user/:id()/donation",
    meta: donationbRmyfN5ChKMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/donation.vue")
  },
  {
    name: "user-id-donation___en",
    path: "/en/user/:id()/donation",
    meta: donationbRmyfN5ChKMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/donation.vue")
  },
  {
    name: "user-id-donation___th",
    path: "/th/user/:id()/donation",
    meta: donationbRmyfN5ChKMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/donation.vue")
  },
  {
    name: "user-id-index-postId___ko",
    path: "/user/:id()/:postId()",
    meta: _91postId_93cA4jgrYoheMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/index/[postId].vue")
  },
  {
    name: "user-id-index-postId___en",
    path: "/en/user/:id()/:postId()",
    meta: _91postId_93cA4jgrYoheMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/index/[postId].vue")
  },
  {
    name: "user-id-index-postId___th",
    path: "/th/user/:id()/:postId()",
    meta: _91postId_93cA4jgrYoheMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/index/[postId].vue")
  },
  {
    name: "user-id-index___ko",
    path: "/user/:id()",
    meta: index499ANqQFumMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/index/index.vue")
  },
  {
    name: "user-id-index___en",
    path: "/en/user/:id()",
    meta: index499ANqQFumMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/index/index.vue")
  },
  {
    name: "user-id-index___th",
    path: "/th/user/:id()",
    meta: index499ANqQFumMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/index/index.vue")
  },
  {
    name: "user-id-reward___ko",
    path: "/user/:id()/reward",
    meta: reward366XhI1KZPMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/reward.vue")
  },
  {
    name: "user-id-reward___en",
    path: "/en/user/:id()/reward",
    meta: reward366XhI1KZPMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/reward.vue")
  },
  {
    name: "user-id-reward___th",
    path: "/th/user/:id()/reward",
    meta: reward366XhI1KZPMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/[id]/reward.vue")
  },
  {
    name: "user-sponsors___ko",
    path: "/user/sponsors",
    meta: indexToCllgcvNXMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/sponsors/index.vue")
  },
  {
    name: "user-sponsors___en",
    path: "/en/user/sponsors",
    meta: indexToCllgcvNXMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/sponsors/index.vue")
  },
  {
    name: "user-sponsors___th",
    path: "/th/user/sponsors",
    meta: indexToCllgcvNXMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/user/sponsors/index.vue")
  },
  {
    name: "w3cf-detail-id___ko",
    path: "/w3cf/detail/:id()",
    meta: _91id_93MKSRYJJaaOMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/w3cf/detail/[id].vue")
  },
  {
    name: "w3cf-detail-id___en",
    path: "/en/w3cf/detail/:id()",
    meta: _91id_93MKSRYJJaaOMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/w3cf/detail/[id].vue")
  },
  {
    name: "w3cf-detail-id___th",
    path: "/th/w3cf/detail/:id()",
    meta: _91id_93MKSRYJJaaOMeta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/w3cf/detail/[id].vue")
  },
  {
    name: "w3cf___ko",
    path: "/w3cf",
    meta: indexBQ92WT2Sk0Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/w3cf/index.vue")
  },
  {
    name: "w3cf___en",
    path: "/en/w3cf",
    meta: indexBQ92WT2Sk0Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/w3cf/index.vue")
  },
  {
    name: "w3cf___th",
    path: "/th/w3cf",
    meta: indexBQ92WT2Sk0Meta || {},
    component: () => import("/codebuild/output/src2563597518/src/pages/w3cf/index.vue")
  }
]